import React from "react";

import Header from "../components/HeaderComponent";
import Contact from "../components/ContactComponent";
import Skills from "../components/SkillsComponent";
import Footer from "../components/FooterComponent";

const HomePage = () => (
    <React.Fragment>
        <Header />

        <Contact />

        <Skills />

        <Footer />
    </React.Fragment>
 );

 export default HomePage;