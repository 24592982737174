import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import ReactTypingEffect from 'react-typing-effect';

const Header = styled("div")`
    background-image: url(../img/header.png);
    background-size: cover;
    position: relative;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Logo = styled("img")`
    width: 10em;
    height: 10em;
    opacity: .9;
    margin-bottom: 1em;
`;

const Text = styled(Typography)`
    && {
        color: #ffffff;
        font-weight: 900;
        text-align: center;
    }

    @media only screen and (max-width: 900px) {
        && {
            font-size: 1.75rem;
        }
    }
`;


const HeaderComponent = () => (
  <Header>
    <Logo
      src={process.env.PUBLIC_URL + "/img/ellenlangelaar.png"}
      alt="Ellen Langelaar Logo"
    />
    <Text variant="h2">Hi, I am Ellen and I </Text>
    <Text variant="h2">
      <ReactTypingEffect
        typingDelay={100}
        eraseDelay={100}
        text={["create solutions.", "build teams.", "innovate business.", "create architectures.", "lead amazing teams.", "innovate IT", "build applications"]}
      />
    </Text>
  </Header>
 );

 export default HeaderComponent;